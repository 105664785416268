<template lang="pug">
span(v-if='data.isDisplayed') {{ data.orderText }}
  span(v-if='data.seconds' class='text-current font-bold whitespace-nowrap mt-1 md:mt-0')
    span(v-if='data.hours' ref='hours' class='inline-block' :style='{ width: data.hrsWidth }') {{ data.hours }}
    span(ref='minutes' class='inline-block' :style='{ width: data.minsWidth }') {{ data.minutes }}
    span(ref='seconds' class='inline-block' :style='{ width: data.secsWidth }') {{ data.seconds }}
</template>

<script setup lang="ts">
const { shippingData } = defineProps<{ shippingData: ProductLine['shipping'] }>()
const hours = ref<HTMLElement | null>(null)
const minutes = ref<HTMLElement | null>(null)
const seconds = ref<HTMLElement | null>(null)
const data = ref({
  hours: '00h',
  minutes: '00m',
  seconds: '00s',
  hrsWidth: '',
  minsWidth: '',
  secsWidth: '',
  orderText: '',
  isDisplayed: true,
  intervalId: null,
})

let intervalId: ReturnType<typeof setInterval> | null = null

onMounted(() => {
  getTimerWidths()
  countDown()
})

onUnmounted(() => {
  if (intervalId) clearInterval(intervalId)
})

function getTimerWidths() {
  if (!data.value.isDisplayed) return
  if (hours.value) data.value.hrsWidth = `${hours.value.clientWidth + 3}px`
  if (minutes.value) data.value.minsWidth = `${minutes.value.clientWidth + 3}px`
  if (seconds.value) data.value.secsWidth = `${seconds.value.clientWidth + 3}px`
}
function countDown() {
  timeRemaining()
  intervalId = setInterval(timeRemaining, 1000)
}
function timeRemaining() {
  const obj = getTimeRemainingText(shippingData)

  if (obj.minutes) {
    data.value.orderText = obj.orderText
    data.value.hours = obj.hours
    data.value.minutes = obj.minutes
    data.value.seconds = obj.seconds
  } else if (obj.orderText) {
    data.value.orderText = obj.orderText
    data.value.seconds = ''
    if (intervalId) clearInterval(intervalId)
  } else {
    data.value.isDisplayed = false
    if (intervalId) clearInterval(intervalId)
  }
}
</script>
