<template lang="pug">
span {{ arrivesText }}&nbsp;
  span(class='font-bold') {{ getDeliveryDate }}
</template>

<script setup lang="ts">
const { shippingData } = defineProps<{ shippingData: ProductLine['shipping'] }>()
const arrivesText = computed(() => getEddText(shippingData))
const getDeliveryDate = computed(() => getEddDate(shippingData))
</script>
